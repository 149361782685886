import * as React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Box,
} from "@mui/material";

export default function ImgMediaCard(props) {
  const [vContenidoHTML, setContenidoHTML] = React.useState(
    props.vContenidoHTML !== undefined ? props.vContenidoHTML : undefined
  );
  const [vImagen, setImagen] = React.useState(
    props.vImagen !== undefined
      ? props.vImagen !== "?"
        ? props.vImagen
        : "/default.webp"
      : "/default.webp"
  );
  const [vTitulo, setTitulo] = React.useState(
    props.vTitulo !== undefined ? props.vTitulo : "Título"
  );
  const [vDescripcion, setDescripcion] = React.useState(
    props.vDescripcion !== undefined ? props.vDescripcion : "Descripción"
  );
  const [vUrl, setUrl] = React.useState(
    props.vUrl !== undefined ? props.vUrl : "URL"
  );

const mSeleccionMedia=()=>{
    if (vContenidoHTML!==undefined) {
        return vContenidoHTML
    } else {
        return (
          <CardMedia
            component="img"
            alt="Servicio Social"
            height="200"
            src={vImagen}
          />
        );
    }
}


  return (
    <Card
      sx={{
        maxHeight: 400,
        minHeight: 400,
        maxWidth: 345,
        minWidth: 345,
        border: "0.5px solid black",
      }}
    >
      {mSeleccionMedia()}
      <CardContent sx={{ maxHeight: 130, minHeight: 130, overflowY: "auto" }}>
        <Typography gutterBottom variant="h5" component="div">
          {vTitulo}
        </Typography>
        <Typography
          sx={{ textAlign: "justify" }}
          variant="body2"
          color="text.secondary"
        >
          {vDescripcion}
        </Typography>
        <br />
        <Typography variant="body2" color="text.secondary">
          {vUrl}
        </Typography>
      </CardContent>
      <CardActions>
        {vUrl !== "URL" && vUrl !== "?" && (
          <Button href={vUrl} target="_blank" size="small">
            Ir
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
