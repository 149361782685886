import React, { useState } from "react";
import {
  Tooltip,
  Container,
  Button,
  ButtonGroup,
  Stack,
  Grid,
  Divider,
  Typography,
} from "@mui/material";
import Carta from "./Componentes/Carta/Carta";

import LanguageIcon from "@mui/icons-material/Language";
import InfoIcon from "@mui/icons-material/Info";
import ContactMailIcon from "@mui/icons-material/ContactMail";

export default function App() {
  var vImagenes = [
    "https://cdn.pixabay.com/photo/2021/09/10/20/02/cats-6614088_960_720.jpg",
    "https://cdn.pixabay.com/photo/2020/11/27/06/58/cat-5781057_960_720.jpg",
    "https://cdn.pixabay.com/photo/2021/10/02/07/40/ink-6674441_960_720.jpg",
    "https://cdn.pixabay.com/photo/2021/07/02/18/13/lion-6382207_960_720.png",
    "https://cdn.pixabay.com/photo/2022/01/11/17/04/city-6931092_960_720.jpg",
    "https://cdn.pixabay.com/photo/2021/12/08/13/39/snail-6855730_960_720.jpg",
    "https://cdn.pixabay.com/photo/2021/11/14/07/28/background-6793549_960_720.jpg",
    "https://cdn.pixabay.com/photo/2020/11/22/14/58/earth-5766860_960_720.png",
    "https://cdn.pixabay.com/photo/2020/10/03/06/45/sketch-5622725_960_720.jpg",
  ];

  const k = () => {
    var myHeaders = new Headers();
    myHeaders.append("Host", "serviciosocial.tecnologinc.com");
    myHeaders.append("Referer", "serviciosocial.tecnologinc.com");
    myHeaders.append("Origin", "serviciosocial.tecnologinc.com");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch("https://serviciosocial.tecnologinc.com", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  const mHeader = () => (
    <Grid
      spacing={3}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs>
        <Typography variant="h3" component="h1">
          Tecnologinc
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <ButtonGroup size="large" aria-label="large button group">
          {buttons}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
  const buttons = [
    <Tooltip title="Aplicaciones Web" arrow>
      <Button key="one">
        <LanguageIcon color="primary" />
      </Button>
    </Tooltip>,
    <Tooltip title="Nosotros" arrow>
      <Button key="two">
        <InfoIcon color="primary" />
      </Button>
    </Tooltip>,
    <Tooltip title="Contacto" arrow>
      <Button key="three">
        <ContactMailIcon color="primary" />
      </Button>
    </Tooltip>,
  ];

  const mBody = () => (
    <Stack
      sx={{ width: "100%" }}
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={2}
    >
      <Grid
        spacing={2}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs="auto">
          <Carta
            vContenidoHTML={
              <div
                style={{
                  height: "200px",
                  backgroundImage:
                    "linear-gradient(90deg,#61866d,#6f8d6a,#819366,#959863,#ab9c63)",
                  display: "grid",
                  gridTemplateRows: "repeat(2, auto)",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  width="250px"
                  class="Imagen__Inicio"
                  src="https://serviciosocial.tecnologinc.com/img/logo_uaemamex.png"
                />
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "700",
                    fontSize: "35px",
                    color: "#fff",
                    textShadow:
                      "2px 8px 6px rgb(0 0 0 / 20%), 0 -5px 35px hsl(0deg 0% 100% / 30%)",
                    fontFamily: "sans-serif",
                  }}
                >
                  UAP Tiangistenco
                </p>
              </div>
            }
            vImagen={vImagenes[Math.floor(Math.random() * vImagenes.length)]}
            vTitulo="Servicio Social"
            vDescripcion="Esta es la descripcion del servicio social."
            vUrl="https://serviciosocial.tecnologinc.com/"
          />
        </Grid>
        <Grid item xs="auto">
          <Carta vImagen="?" vTitulo="?" vDescripcion="?" vUrl="?" />
        </Grid>
        <Grid item xs="auto">
          <Carta vImagen="?" vTitulo="?" vDescripcion="?" vUrl="?" />
        </Grid>
      </Grid>
    </Stack>
  );

  const Inicio = () => (
    <>
      <Container key={Date.now()} maxWidth={"false"}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {mHeader()}
          <Divider orientation="horizontal" flexItem>
            <Typography variant="h6" component="div">
              Aplicaciones web creadas
            </Typography>
          </Divider>
          {mBody()}
          <Divider orientation="horizontal" flexItem />
          <Typography variant="body1" component="footer">
            Tecnologinc.com
          </Typography>
        </Stack>
      </Container>
    </>
  );

  return <>{Inicio()}</>;
}
